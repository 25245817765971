import log from '../log';

export default function swiperTest($) {
	let swiperTest = $('.swiper-test');
	if( swiperTest.length ){
		log('Swiper Seven Step init');
		return new Swiper(swiperTest[0], {
			direction: 'horizontal',
			autoHeight: !0,
			slidesPerView: 1,
			allowTouchMove: !1
		});
	}
}