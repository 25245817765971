import LS from '../LS';
import log from '../log';
import yatarget from '../yatarget';

export function oneFormAction() {
	let run = true;
	return function( wrForm, thunk, files ){
		if( run && wrForm.length ){
			formAction( wrForm, thunk );
			run = false;
		}
	}
};
export function formAction( wrForm, thunk, files ) {
	/*Mask phone init*/
	wrForm.find('input.phone').inputmask({mask:'+7 (999) 999-99-99'});
	/*Start form*/
	if( wrForm.hasClass( 'simpleform' ) ){
		wrForm.submit(function(e){
			e = e || window.event;
			e.preventDefault();
			e.stopPropagation();
			let
				_ = $(this),
				_cbi = _.find('.cn3bie-checkbox__input'),
				_pend = _.find('.pending');
			if( !_pend.length ) {
				_pend = $('<span class="pending"></span>').hide();
				_.find('.form-content').append(_pend);
			}
			_pend.fadeIn();
			_cbi.removeClass('error-polit');
			LS.set( 'name', _.find('.name').val());
			LS.set( 'email', _.find('.email').val());
			LS.set( 'phone', _.find('.phone').val());
			$.post({url:'/mail/',data: _.serialize(),dataType:'JSON'})
				.done(data => {
					if( data === 'OK' ){
						yatarget('targetHey');
						_.find('input[type="text"],input[type="email"]').val('');
						thunk( _ );
					}else{
						for(let key in data){
							_.find('input[name="'+key+'"]').addClass('wpcf7-not-valid');
						}
					}
				})
				.fail(( jqXHR, textStatus, errorThrown ) => {
					log('ОШИБКИ AJAX запроса: ' + textStatus );
				})
				.always(() => {
					_pend.fadeOut();
				});
		});
	}

	if( wrForm.hasClass( 'attachment' ) ){
		wrForm.find('input[type=file]').change(function(){
			files = this.files;
		});
		wrForm.submit(function(e){
			e = e || window.event;
			e.stopPropagation();
			e.preventDefault();
			let
				_ = $(this),
				data = new FormData(),
				_cbi = _.find('.cn3bie-checkbox__input');
			$.each( files, function( key, value ){
				data.append( key, value );
			});
			$.each( _.find('input'), function( key, inp ){
				data.append( $(inp).attr('name'), $(inp).val() );
			});
			$.post({url: '/mail/',data: data,cache: !1,dataType: 'JSON',processData: !1,contentType: !1})
				.done(function( data){
					if(data==='OK'){
						yatarget('targetHey');
						_.find('input[type="text"],input[type="email"]').val('');
						thunk( _ );
					}else{
						for(let key in data){
							_.find('input[name="'+key+'"]').addClass('wpcf7-not-valid');
							if( key==='file' ) _.parents('.wr-if').addClass('wpcf7-not-valid');
						}
					}
				})
				.fail(function( jqXHR, textStatus, errorThrown ){
					log('ОШИБКИ AJAX запроса: ' + textStatus );
				});
		});

	}
	wrForm.find('input').on('change input',function(){
		$(this).removeClass('wpcf7-not-valid');
	});
	wrForm.find('.bl-politic-conf').change(function(){
		let
			_ = $(this),
			_chipi = _.find('.input');
		if( !_chipi.prop('checked') ) _.addClass('error-polit');
		else _.removeClass('error-polit');
	});
	/*end Form*/
};